import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
// ------------------------------------------------------------------------
// gsap anim

gsap.from(".aboutImgAnim1", {
  duration: 2,
  scrollTrigger: {
    trigger: ".aboutImgAnim1",
    scroller: "body",
  },
  width: "0vw",
});

gsap.from(".aboutImgAnim2", {
  duration: 2,
  scrollTrigger: {
    trigger: ".aboutImgAnim2",
    scroller: "body",
  },
  height: "0vh",
});

gsap.from(".aboutImgAnim3", {
  duration: 2,
  scrollTrigger: {
    trigger: ".aboutImgAnim3",
    scroller: "body",
  },
  width: "0vw",
  height: "auto",
  // ease: "Expo.easeInOut",
});

// gsap.to(".cafeBgLayer", {
//   duration: 2,
//   scrollTrigger: {
//     trigger: ".cafeBgLayer",
//     scroller: "body",
//   },
//   height: 0,
//   delay: 0.5,

//   // ease: "Expo.easeInOut",
// });

// gsap.to(".wineBgLayer", {
//   duration: 2,
//   scrollTrigger: {
//     trigger: ".wineBgLayer",
//     scroller: "body",
//   },
//   height: 0,
//   delay: 0.5,

//   // ease: "Expo.easeInOut",
// });

// ------------------------------------------------------------------------
// Rooms section slideshow
var sickPrimary = {
  autoplay: true,
  autoplaySpeed: 2400,
  slidesToShow: 2,
  slidesToScroll: 1,
  speed: 1800,
  cssEase: "cubic-bezier(.84, 0, .08, .99)",
  asNavFor: ".text-slider",
  centerMode: true,
  prevArrow: $(".prev"),
  nextArrow: $(".next"),
};

var sickSecondary = {
  autoplay: true,
  autoplaySpeed: 2400,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1800,
  cssEase: "cubic-bezier(.84, 0, .08, .99)",
  asNavFor: ".image-slider",
  prevArrow: $(".prev"),
  nextArrow: $(".next"),
};

$(".image-slider").slick(sickPrimary);
$(".text-slider").slick(sickSecondary);

// Rooms section slideshow end
// ------------------------------------------------------------------------

function scrollToSec(clickEl, scrollToEl) {
  document.getElementById(clickEl).addEventListener("click", () => {
    const roomSection = document.getElementById(scrollToEl);
    gsap.to(window, {
      scrollTo: roomSection,
      duration: 1.5,
      opacity: 1,
      ease: "Expo.easeInOut",
    });
  });
}

scrollToSec("quickRoomsNav", "rooms_section");
scrollToSec("quickDiningNav", "dining_section");
scrollToSec("quickContactNav", "contact_section");

scrollToSec("footer_about_link", "aboutUs_Section");




var $w = $(window).scroll(function () {

    if ($w.scrollTop() > 600) {
      gsap.to(".open_mobile_nav", {
        duration: 0.5,
        opacity: 1,
      });
    } else {
      gsap.to(".open_mobile_nav", {
        duration: 0.5,
        opacity: 0,
      });
    }
  
});



